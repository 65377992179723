import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import Layout from 'components/Layout'
import image1 from 'images/project-20b.png'
import image2 from 'images/project-20c.png'
import image3 from 'images/project-20d.png'

const Page = () => (
  <Layout>
    <Helmet
      title="Looking smart and on the level"
      meta={[
        {
          name: 'description',
          content: 'When the trust was asked to check out at an &lsquo;un-level&rsquo; floor in a local home, the team soon uncovered a more troublesome issue.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Looking smart and on the level"
      intro="When the trust was asked to check out at an &lsquo;un-level&rsquo; floor in a local home, the team soon uncovered a more troublesome issue."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            When the trust was asked to check out at an &lsquo;un-level&rsquo; floor in a local home, the team soon uncovered a more troublesome issue. Whilst a laser level revealed that the floor was in fact &ndash; even, and wouldn&rsquo;t need repiling, a broken drainpipe underneath the home was causing serious water damage to the basement and a very damp lawn. To add to this, the existing roof gutters were failing and causing more dampness around the home.
          </Paragraph>
          <Paragraph>
            The money that would have spent on repiling was instead used to carry out overdue maintenance to the house and address severe drainage issue. It&rsquo;s fair to say there was quite a list of jobs to get through! Firstly, the broken drainage was repaired and new gutters were installed. The dampness quickly disappeared and the lawn dried out. Then the team set their attention to the house, which required refurbishment inside and out.
          </Paragraph>
          <Paragraph>
            Renovations were carried out in the kitchen, bathroom and laundry, including the installation of new extraction fans to ensure good ventilation. The existing windows needed significant work to get them operational, with the fitting of new hinges. Existing rimu floors were revarnished, providing a real lift to the interior of the house and the walls received a fresh lick of paint; impressive work carried out by the family, with trust staff on hand to assist.
          </Paragraph>
          <Paragraph>
            Outside, the exterior weatherboards were repaired or replaced depending on their condition and the outside of the house was repainted. This collaborative effort has resulted in a smart looking home ready for summer. The interior and exterior spaces feel drier and healthy for all of the family to enjoy.
          </Paragraph>
          <img className={styles.image} src={image1} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
